exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-community-jsx": () => import("./../../../src/pages/community.jsx" /* webpackChunkName: "component---src-pages-community-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-integrations-jsx": () => import("./../../../src/pages/integrations.jsx" /* webpackChunkName: "component---src-pages-integrations-jsx" */),
  "component---src-pages-profiles-florian-valeye-jsx": () => import("./../../../src/pages/profiles/florian-valeye.jsx" /* webpackChunkName: "component---src-pages-profiles-florian-valeye-jsx" */),
  "component---src-pages-profiles-gerhard-brueckl-jsx": () => import("./../../../src/pages/profiles/gerhard-brueckl.jsx" /* webpackChunkName: "component---src-pages-profiles-gerhard-brueckl-jsx" */),
  "component---src-pages-profiles-tyler-croy-jsx": () => import("./../../../src/pages/profiles/tyler-croy.jsx" /* webpackChunkName: "component---src-pages-profiles-tyler-croy-jsx" */),
  "component---src-pages-sharing-jsx": () => import("./../../../src/pages/sharing.jsx" /* webpackChunkName: "component---src-pages-sharing-jsx" */),
  "component---src-templates-collections-blog-jsx-content-file-path-src-blog-2019-04-22-delta-lake-0-1-0-released-index-mdx": () => import("./../../../src/templates/collections/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2019-04-22-delta-lake-0-1-0-released/index.mdx" /* webpackChunkName: "component---src-templates-collections-blog-jsx-content-file-path-src-blog-2019-04-22-delta-lake-0-1-0-released-index-mdx" */),
  "component---src-templates-collections-blog-jsx-content-file-path-src-blog-2019-07-19-delta-lake-0-2-0-released-index-mdx": () => import("./../../../src/templates/collections/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2019-07-19-delta-lake-0-2-0-released/index.mdx" /* webpackChunkName: "component---src-templates-collections-blog-jsx-content-file-path-src-blog-2019-07-19-delta-lake-0-2-0-released-index-mdx" */),
  "component---src-templates-collections-blog-jsx-content-file-path-src-blog-2019-08-01-delta-lake-0-3-0-released-index-mdx": () => import("./../../../src/templates/collections/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2019-08-01-delta-lake-0-3-0-released/index.mdx" /* webpackChunkName: "component---src-templates-collections-blog-jsx-content-file-path-src-blog-2019-08-01-delta-lake-0-3-0-released-index-mdx" */),
  "component---src-templates-collections-blog-jsx-content-file-path-src-blog-2019-10-01-delta-lake-0-4-0-released-index-mdx": () => import("./../../../src/templates/collections/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2019-10-01-delta-lake-0-4-0-released/index.mdx" /* webpackChunkName: "component---src-templates-collections-blog-jsx-content-file-path-src-blog-2019-10-01-delta-lake-0-4-0-released-index-mdx" */),
  "component---src-templates-collections-blog-jsx-content-file-path-src-blog-2019-10-03-delta-lake-newsletter-index-mdx": () => import("./../../../src/templates/collections/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2019-10-03-delta-lake-newsletter/index.mdx" /* webpackChunkName: "component---src-templates-collections-blog-jsx-content-file-path-src-blog-2019-10-03-delta-lake-newsletter-index-mdx" */),
  "component---src-templates-collections-blog-jsx-content-file-path-src-blog-2020-02-21-delta-lake-online-tech-talks-index-mdx": () => import("./../../../src/templates/collections/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2020-02-21-delta-lake-online-tech-talks/index.mdx" /* webpackChunkName: "component---src-templates-collections-blog-jsx-content-file-path-src-blog-2020-02-21-delta-lake-online-tech-talks-index-mdx" */),
  "component---src-templates-collections-blog-jsx-content-file-path-src-blog-2020-03-13-diving-into-delta-lake-online-tech-talk-series-index-mdx": () => import("./../../../src/templates/collections/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2020-03-13-diving-into-delta-lake-online-tech-talk-series/index.mdx" /* webpackChunkName: "component---src-templates-collections-blog-jsx-content-file-path-src-blog-2020-03-13-diving-into-delta-lake-online-tech-talk-series-index-mdx" */),
  "component---src-templates-collections-blog-jsx-content-file-path-src-blog-2020-03-20-delta-lake-newsletter-index-mdx": () => import("./../../../src/templates/collections/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2020-03-20-delta-lake-newsletter/index.mdx" /* webpackChunkName: "component---src-templates-collections-blog-jsx-content-file-path-src-blog-2020-03-20-delta-lake-newsletter-index-mdx" */),
  "component---src-templates-collections-blog-jsx-content-file-path-src-blog-2020-04-22-delta-lake-0-6-0-released-index-mdx": () => import("./../../../src/templates/collections/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2020-04-22-delta-lake-0-6-0-released/index.mdx" /* webpackChunkName: "component---src-templates-collections-blog-jsx-content-file-path-src-blog-2020-04-22-delta-lake-0-6-0-released-index-mdx" */),
  "component---src-templates-collections-user-stories-jsx-content-file-path-src-user-stories-adobe-index-mdx": () => import("./../../../src/templates/collections/user-stories.jsx?__contentFilePath=/opt/build/repo/src/user-stories/adobe/index.mdx" /* webpackChunkName: "component---src-templates-collections-user-stories-jsx-content-file-path-src-user-stories-adobe-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2019-04-22-delta-lake-0-1-0-released-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2019-04-22-delta-lake-0-1-0-released/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2019-04-22-delta-lake-0-1-0-released-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2019-07-19-delta-lake-0-2-0-released-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2019-07-19-delta-lake-0-2-0-released/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2019-07-19-delta-lake-0-2-0-released-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2019-08-01-delta-lake-0-3-0-released-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2019-08-01-delta-lake-0-3-0-released/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2019-08-01-delta-lake-0-3-0-released-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2019-10-01-delta-lake-0-4-0-released-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2019-10-01-delta-lake-0-4-0-released/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2019-10-01-delta-lake-0-4-0-released-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2019-10-03-delta-lake-newsletter-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2019-10-03-delta-lake-newsletter/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2019-10-03-delta-lake-newsletter-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2019-12-13-delta-lake-0-5-0-released-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2019-12-13-delta-lake-0-5-0-released/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2019-12-13-delta-lake-0-5-0-released-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2020-02-21-delta-lake-online-tech-talks-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2020-02-21-delta-lake-online-tech-talks/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2020-02-21-delta-lake-online-tech-talks-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2020-03-13-diving-into-delta-lake-online-tech-talk-series-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2020-03-13-diving-into-delta-lake-online-tech-talk-series/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2020-03-13-diving-into-delta-lake-online-tech-talk-series-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2020-03-20-delta-lake-newsletter-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2020-03-20-delta-lake-newsletter/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2020-03-20-delta-lake-newsletter-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2020-04-22-delta-lake-0-6-0-released-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2020-04-22-delta-lake-0-6-0-released/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2020-04-22-delta-lake-0-6-0-released-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2020-05-26-delta-lake-0-6-1-released-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2020-05-26-delta-lake-0-6-1-released/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2020-05-26-delta-lake-0-6-1-released-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2020-06-18-delta-lake-0-7-0-released-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2020-06-18-delta-lake-0-7-0-released/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2020-06-18-delta-lake-0-7-0-released-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2020-06-22-delta-lake-sessions-at-sparkai-summit-north-america-2020-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2020-06-22-delta-lake-sessions-at-sparkai-summit-north-america-2020/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2020-06-22-delta-lake-sessions-at-sparkai-summit-north-america-2020-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2020-09-16-getting-started-with-delta-lake-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2020-09-16-getting-started-with-delta-lake/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2020-09-16-getting-started-with-delta-lake-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2020-09-25-salesforce-engineering-engagement-activity-delta-lake-redshift-spectrum-supports-delta-lake-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2020-09-25-salesforce-engineering-engagement-activity-delta-lake-redshift-spectrum-supports-delta-lake/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2020-09-25-salesforce-engineering-engagement-activity-delta-lake-redshift-spectrum-supports-delta-lake-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2020-10-14-salesforce-engineering-global-synchronousness-and-ordering-in-delta-lake-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2020-10-14-salesforce-engineering-global-synchronousness-and-ordering-in-delta-lake/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2020-10-14-salesforce-engineering-global-synchronousness-and-ordering-in-delta-lake-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2020-10-20-salesforce-engineering-delta-lake-blog-series-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2020-10-20-salesforce-engineering-delta-lake-blog-series/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2020-10-20-salesforce-engineering-delta-lake-blog-series-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2021-02-04-delta-lake-0-8-0-released-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2021-02-04-delta-lake-0-8-0-released/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2021-02-04-delta-lake-0-8-0-released-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2021-03-02-ama-growing-the-delta-lake-ecosystem-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2021-03-02-ama-growing-the-delta-lake-ecosystem/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2021-03-02-ama-growing-the-delta-lake-ecosystem-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2021-03-02-salesforce-engineering-delta-lake-tech-talk-series-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2021-03-02-salesforce-engineering-delta-lake-tech-talk-series/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2021-03-02-salesforce-engineering-delta-lake-tech-talk-series-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2021-05-24-delta-lake-1-0-0-released-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2021-05-24-delta-lake-1-0-0-released/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2021-05-24-delta-lake-1-0-0-released-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2021-09-16-delta-lake-survey-2021-h-2-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2021-09-16-delta-lake-survey_2021-h2/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2021-09-16-delta-lake-survey-2021-h-2-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2021-11-16-power-bi-delta-sharing-connector-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2021-11-16-power-bi-delta-sharing-connector/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2021-11-16-power-bi-delta-sharing-connector-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2021-12-01-delta-sharing-0-3-0-released-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2021-12-01-delta-sharing-0-3-0-released/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2021-12-01-delta-sharing-0-3-0-released-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2021-12-03-delta-release-1-1-0-released-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2021-12-03-delta-release-1-1-0-released/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2021-12-03-delta-release-1-1-0-released-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-01-06-delta-connectors-0-3-0-released-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2022-01-06-delta-connectors-0-3-0-released/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-01-06-delta-connectors-0-3-0-released-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-03-11-delta-sharing-0-4-0-released-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2022-03-11-delta-sharing-0-4-0-released/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-03-11-delta-sharing-0-4-0-released-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-04-27-writing-to-delta-lake-from-apache-flink-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2022-04-27-writing-to-delta-lake-from-apache-flink/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-04-27-writing-to-delta-lake-from-apache-flink-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-05-05-delta-lake-1-2-released-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2022-05-05-delta-lake-1-2-released/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-05-05-delta-lake-1-2-released-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-05-18-multi-cluster-writes-to-delta-lake-storage-in-s-3-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2022-05-18-multi-cluster-writes-to-delta-lake-storage-in-s3/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-05-18-multi-cluster-writes-to-delta-lake-storage-in-s-3-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-08-02-delta-2-0-the-foundation-of-your-data-lake-is-open-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2022-08-02-delta-2-0-the-foundation-of-your-data-lake-is-open/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-08-02-delta-2-0-the-foundation-of-your-data-lake-is-open-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-08-11-apache-flink-source-connector-for-delta-lake-tables-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2022-08-11-apache-flink-source-connector-for-delta-lake-tables/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-08-11-apache-flink-source-connector-for-delta-lake-tables-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-08-29-delta-lake-drop-column-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2022-08-29-delta-lake-drop-column/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-08-29-delta-lake-drop-column-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-09-14-why-migrate-lakehouse-delta-lake-tmo-dsna-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2022-09-14-why-migrate-lakehouse-delta-lake-tmo-dsna/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-09-14-why-migrate-lakehouse-delta-lake-tmo-dsna-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-09-23-convert-parquet-to-delta-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2022-09-23-convert-parquet-to-delta/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-09-23-convert-parquet-to-delta-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-10-03-rollback-delta-lake-restore-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2022-10-03-rollback-delta-lake-restore/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-10-03-rollback-delta-lake-restore-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-10-10-delta-sharing-0-5-0-released-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2022-10-10-delta-sharing-0-5-0-released/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-10-10-delta-sharing-0-5-0-released-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-10-15-version-pandas-dataset-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2022-10-15-version-pandas-dataset/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-10-15-version-pandas-dataset-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-10-25-create-delta-lake-tables-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2022-10-25-create-delta-lake-tables/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-10-25-create-delta-lake-tables-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-11-01-pyspark-save-mode-append-overwrite-error-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2022-11-01-pyspark-save-mode-append-overwrite-error/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-11-01-pyspark-save-mode-append-overwrite-error-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-11-16-delta-lake-schema-enforcement-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2022-11-16-delta-lake-schema-enforcement/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-11-16-delta-lake-schema-enforcement-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-11-21-delta-lake-contraints-check-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2022-11-21-delta-lake-contraints-check/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-11-21-delta-lake-contraints-check-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-12-07-delete-rows-from-delta-lake-table-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2022-12-07-delete-rows-from-delta-lake-table/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-12-07-delete-rows-from-delta-lake-table-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-12-08-data-sharing-across-government-delta-sharing-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2022-12-08-data-sharing-across-government-delta-sharing/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-12-08-data-sharing-across-government-delta-sharing-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-12-13-sagemaker-emr-delta-lake-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2022-12-13-sagemaker-emr-delta-lake/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-12-13-sagemaker-emr-delta-lake-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-12-22-reading-delta-lake-tables-polars-dataframe-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2022-12-22-reading-delta-lake-tables-polars-dataframe/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2022-12-22-reading-delta-lake-tables-polars-dataframe-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-01-18-add-remove-partition-delta-lake-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2023-01-18-add-remove-partition-delta-lake/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-01-18-add-remove-partition-delta-lake-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-01-25-delta-lake-small-file-compaction-optimize-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2023-01-25-delta-lake-small-file-compaction-optimize/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-01-25-delta-lake-small-file-compaction-optimize-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-02-01-delta-lake-time-travel-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2023-02-01-delta-lake-time-travel/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-02-01-delta-lake-time-travel-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-02-08-delta-lake-schema-evolution-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2023-02-08-delta-lake-schema-evolution/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-02-08-delta-lake-schema-evolution-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-02-14-delta-lake-merge-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2023-02-14-delta-lake-merge/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-02-14-delta-lake-merge-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-02-27-deltalake-0-7-0-release-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2023-02-27-deltalake-0.7.0-release/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-02-27-deltalake-0-7-0-release-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-03-07-delta-lake-spark-development-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2023-03-07-delta-lake-spark-development/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-03-07-delta-lake-spark-development-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-03-22-convert-csv-to-delta-lake-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2023-03-22-convert-csv-to-delta-lake/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-03-22-convert-csv-to-delta-lake-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-03-23-deltaray-part-1-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2023-03-23-deltaray-part1/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-03-23-deltaray-part-1-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-04-01-create-append-delta-lake-table-pandas-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2023-04-01-create-append-delta-lake-table-pandas/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-04-01-create-append-delta-lake-table-pandas-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-04-06-deltalake-aws-lambda-wrangler-pandas-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2023-04-06-deltalake-aws-lambda-wrangler-pandas/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-04-06-deltalake-aws-lambda-wrangler-pandas-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-04-12-delta-lake-generated-columns-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2023-04-12-delta-lake-generated-columns/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-04-12-delta-lake-generated-columns-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-04-19-faster-aggregations-metadata-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2023-04-19-faster-aggregations-metadata/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-04-19-faster-aggregations-metadata-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-04-24-open-source-selfhosted-delta-sharing-server-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2023-04-24-open-source-selfhosted-delta-sharing-server/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-04-24-open-source-selfhosted-delta-sharing-server-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-05-02-delta-lake-2-3-0-released-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2023-05-02-delta-lake-2-3-0-released/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-05-02-delta-lake-2-3-0-released-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-06-03-delta-lake-z-order-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2023-06-03-delta-lake-z-order/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-06-03-delta-lake-z-order-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-06-15-using-ibis-pyspark-delta-lake-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2023-06-15-using-ibis-pyspark-delta-lake/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-06-15-using-ibis-pyspark-delta-lake-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-07-05-deletion-vectors-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2023-07-05-deletion-vectors/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-07-05-deletion-vectors-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-07-07-delta-lake-transaction-log-protocol-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2023-07-07-delta-lake-transaction-log-protocol/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-07-07-delta-lake-transaction-log-protocol-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-07-14-delta-lake-change-data-feed-cdf-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2023-07-14-delta-lake-change-data-feed-cdf/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-07-14-delta-lake-change-data-feed-cdf-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-07-27-delta-lake-table-features-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2023-07-27-delta-lake-table-features/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-07-27-delta-lake-table-features-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-10-22-delta-rs-python-v-0-12-0-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/2023-10-22-delta-rs-python-v0.12.0/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-2023-10-22-delta-rs-python-v-0-12-0-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-daft-delta-lake-integration-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/daft-delta-lake-integration/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-daft-delta-lake-integration-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-kernel-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/delta-kernel/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-kernel-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-3-0-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/delta-lake-3-0/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-3-0-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-3-1-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/delta-lake-3-1/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-3-1-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-3-2-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/delta-lake-3-2/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-3-2-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-4-0-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/delta-lake-4-0/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-4-0-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-aws-glue-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/delta-lake-aws-glue/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-aws-glue-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-clone-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/delta-lake-clone/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-clone-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-etl-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/delta-lake-etl/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-etl-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-jupyter-notebook-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/delta-lake-jupyter-notebook/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-jupyter-notebook-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-medallion-architecture-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/delta-lake-medallion-architecture/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-medallion-architecture-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-optimize-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/delta-lake-optimize/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-optimize-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-performance-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/delta-lake-performance/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-performance-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-replacewhere-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/delta-lake-replacewhere/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-replacewhere-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-s-3-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/delta-lake-s3/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-s-3-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-vs-data-lake-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/delta-lake-vs-data-lake/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-vs-data-lake-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-vs-orc-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/delta-lake-vs-orc/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-vs-orc-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-vs-parquet-comparison-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/delta-lake-vs-parquet-comparison/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-vs-parquet-comparison-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-without-spark-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/delta-lake-without-spark/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-delta-lake-without-spark-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-deltatorch-intro-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/deltatorch-intro/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-deltatorch-intro-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-efficient-delta-vacuum-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/efficient-delta-vacuum/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-efficient-delta-vacuum-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-guide-delta-ai-summit-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/guide-delta-ai-summit/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-guide-delta-ai-summit-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-pandas-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/pandas/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-pandas-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-pros-cons-hive-style-partionining-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/pros-cons-hive-style-partionining/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-pros-cons-hive-style-partionining-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-query-delta-lake-bigquery-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/query-delta-lake-bigquery/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-query-delta-lake-bigquery-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-remove-files-delta-lake-vacuum-command-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/remove-files-delta-lake-vacuum-command/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-remove-files-delta-lake-vacuum-command-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-rivian-delta-go-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/rivian-delta-go/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-rivian-delta-go-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-scaling-graph-data-processing-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/scaling-graph-data-processing/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-scaling-graph-data-processing-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-state-of-the-project-pt-1-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/state-of-the-project-pt1/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-state-of-the-project-pt-1-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-state-of-the-project-pt-2-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/state-of-the-project-pt2/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-state-of-the-project-pt-2-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-structured-spark-streaming-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/structured-spark-streaming/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-structured-spark-streaming-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-unifying-open-table-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/unifying-open-table/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-unifying-open-table-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-unlocking-delta-lake-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/unlocking-delta-lake/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-unlocking-delta-lake-index-mdx" */),
  "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-write-kafka-stream-to-delta-lake-index-mdx": () => import("./../../../src/templates/mdx/blog.jsx?__contentFilePath=/opt/build/repo/src/blog/write-kafka-stream-to-delta-lake/index.mdx" /* webpackChunkName: "component---src-templates-mdx-blog-jsx-content-file-path-src-blog-write-kafka-stream-to-delta-lake-index-mdx" */),
  "component---src-templates-mdx-default-jsx-content-file-path-src-pages-docs-mdx": () => import("./../../../src/templates/mdx/default.jsx?__contentFilePath=/opt/build/repo/src/pages/docs.mdx" /* webpackChunkName: "component---src-templates-mdx-default-jsx-content-file-path-src-pages-docs-mdx" */),
  "component---src-templates-mdx-default-jsx-content-file-path-src-pages-learn-getting-started-mdx": () => import("./../../../src/templates/mdx/default.jsx?__contentFilePath=/opt/build/repo/src/pages/learn/getting-started.mdx" /* webpackChunkName: "component---src-templates-mdx-default-jsx-content-file-path-src-pages-learn-getting-started-mdx" */),
  "component---src-templates-mdx-default-jsx-content-file-path-src-pages-learn-tutorials-mdx": () => import("./../../../src/templates/mdx/default.jsx?__contentFilePath=/opt/build/repo/src/pages/learn/tutorials.mdx" /* webpackChunkName: "component---src-templates-mdx-default-jsx-content-file-path-src-pages-learn-tutorials-mdx" */),
  "component---src-templates-mdx-default-jsx-content-file-path-src-pages-learn-videos-mdx": () => import("./../../../src/templates/mdx/default.jsx?__contentFilePath=/opt/build/repo/src/pages/learn/videos.mdx" /* webpackChunkName: "component---src-templates-mdx-default-jsx-content-file-path-src-pages-learn-videos-mdx" */),
  "component---src-templates-mdx-default-jsx-content-file-path-src-pages-resources-contributing-to-delta-mdx": () => import("./../../../src/templates/mdx/default.jsx?__contentFilePath=/opt/build/repo/src/pages/resources/contributing-to-delta.mdx" /* webpackChunkName: "component---src-templates-mdx-default-jsx-content-file-path-src-pages-resources-contributing-to-delta-mdx" */),
  "component---src-templates-mdx-default-jsx-content-file-path-src-pages-resources-contributing-to-docs-mdx": () => import("./../../../src/templates/mdx/default.jsx?__contentFilePath=/opt/build/repo/src/pages/resources/contributing-to-docs.mdx" /* webpackChunkName: "component---src-templates-mdx-default-jsx-content-file-path-src-pages-resources-contributing-to-docs-mdx" */),
  "component---src-templates-mdx-default-jsx-content-file-path-src-pages-resources-contributors-mdx": () => import("./../../../src/templates/mdx/default.jsx?__contentFilePath=/opt/build/repo/src/pages/resources/contributors.mdx" /* webpackChunkName: "component---src-templates-mdx-default-jsx-content-file-path-src-pages-resources-contributors-mdx" */),
  "component---src-templates-mdx-default-jsx-content-file-path-src-pages-resources-getting-help-mdx": () => import("./../../../src/templates/mdx/default.jsx?__contentFilePath=/opt/build/repo/src/pages/resources/getting-help.mdx" /* webpackChunkName: "component---src-templates-mdx-default-jsx-content-file-path-src-pages-resources-getting-help-mdx" */),
  "component---src-templates-mdx-default-jsx-content-file-path-src-pages-resources-meetings-mdx": () => import("./../../../src/templates/mdx/default.jsx?__contentFilePath=/opt/build/repo/src/pages/resources/meetings.mdx" /* webpackChunkName: "component---src-templates-mdx-default-jsx-content-file-path-src-pages-resources-meetings-mdx" */),
  "component---src-templates-mdx-default-jsx-content-file-path-src-pages-roadmap-mdx": () => import("./../../../src/templates/mdx/default.jsx?__contentFilePath=/opt/build/repo/src/pages/roadmap.mdx" /* webpackChunkName: "component---src-templates-mdx-default-jsx-content-file-path-src-pages-roadmap-mdx" */),
  "component---src-templates-mdx-user-stories-jsx-content-file-path-src-user-stories-adobe-index-mdx": () => import("./../../../src/templates/mdx/user-stories.jsx?__contentFilePath=/opt/build/repo/src/user-stories/adobe/index.mdx" /* webpackChunkName: "component---src-templates-mdx-user-stories-jsx-content-file-path-src-user-stories-adobe-index-mdx" */),
  "component---src-templates-mdx-user-stories-jsx-content-file-path-src-user-stories-claimsforce-index-mdx": () => import("./../../../src/templates/mdx/user-stories.jsx?__contentFilePath=/opt/build/repo/src/user-stories/claimsforce/index.mdx" /* webpackChunkName: "component---src-templates-mdx-user-stories-jsx-content-file-path-src-user-stories-claimsforce-index-mdx" */),
  "component---src-templates-mdx-user-stories-jsx-content-file-path-src-user-stories-kubit-index-mdx": () => import("./../../../src/templates/mdx/user-stories.jsx?__contentFilePath=/opt/build/repo/src/user-stories/kubit/index.mdx" /* webpackChunkName: "component---src-templates-mdx-user-stories-jsx-content-file-path-src-user-stories-kubit-index-mdx" */),
  "component---src-templates-mdx-user-stories-jsx-content-file-path-src-user-stories-lakefs-index-mdx": () => import("./../../../src/templates/mdx/user-stories.jsx?__contentFilePath=/opt/build/repo/src/user-stories/lakefs/index.mdx" /* webpackChunkName: "component---src-templates-mdx-user-stories-jsx-content-file-path-src-user-stories-lakefs-index-mdx" */),
  "component---src-templates-mdx-user-stories-jsx-content-file-path-src-user-stories-scribd-index-mdx": () => import("./../../../src/templates/mdx/user-stories.jsx?__contentFilePath=/opt/build/repo/src/user-stories/scribd/index.mdx" /* webpackChunkName: "component---src-templates-mdx-user-stories-jsx-content-file-path-src-user-stories-scribd-index-mdx" */)
}

